<script>
export default {
  name: 'loginErrorRenderless',
  props: {
    errorMessage: String,
    classList: Object,
    messageList: Array
  },

  render(h) {
    const createFunction = (rowClass, colClass = this.classList.innerClass) => h('div', { attrs: { class: rowClass } }, [
      h('div', { attrs: { class: colClass } }, [
        h('span', {}, this.errorMessage)
      ])
    ]);

    if (this.messageList[0] !== undefined && this.messageList[1] !== undefined) {
      return createFunction(this.classList.error);
    }
    if (this.messageList[0] !== undefined) {
      return createFunction(this.classList.errorLogin);
    }
    if (this.messageList[1] !== undefined) {
      return createFunction(this.classList.errorPassword);
    }
    return createFunction(this.classList.error);
  }
};
</script>
